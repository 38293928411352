import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import SimplePriceSlider from './SimplePriceSlider';

// Custom hook for window size
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  
  useEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  
  return size;
}

// Panel for filter options with buttons for brands or checkboxes for other options
const FilterPanel = React.memo(function FilterPanel({ 
  title, 
  options, 
  selectedOptions, 
  onChange, 
  isOpen, 
  maxHeight, 
  columns = 1,
  className = "",
  useButtons = false,
  useBrandStyle = false
}) {
  if (!isOpen) return null;
  
  return (
    <div className={`mb-6 ${className}`}>
      <div className="w-full mb-3 font-bold text-left text-white text-lg">
        {title}
      </div>

      <div 
        className="overflow-y-auto px-2"
        style={{ maxHeight: maxHeight || "auto" }}
      >
        {useBrandStyle ? (
          // Brand style layout - like in the image
          <div className={`grid grid-cols-${columns} gap-x-2 gap-y-3`}>
            {options.map((option) => {
              const isSelected = selectedOptions.includes(option);
              return (
                <button
                  key={option}
                  onClick={() => onChange(isSelected ? false : true, option)}
                  className={`px-3 py-2 text-sm rounded-md border ${
                    isSelected 
                      ? 'bg-gray-100 border-gray-300 font-semibold' 
                      : 'bg-white border-gray-300'
                  } transition-colors duration-200 text-center w-full h-12 flex items-center justify-center hover:border-black`}
                >
                  {option}
                </button>
              );
            })}
          </div>
        ) : useButtons ? (
          // Button layout for brands
          <div className={`grid grid-cols-${columns} gap-x-2 gap-y-3`}>
            {options.map((option) => {
              const isSelected = selectedOptions.includes(option);
              return (
                <button
                  key={option}
                  onClick={() => onChange(isSelected ? false : true, option)}
                  className={`px-2 py-1 text-xs rounded border ${
                    isSelected 
                      ? 'bg-white text-black border-white' 
                      : 'bg-black bg-opacity-40 text-white border-gray-500 hover:border-white'
                  } transition-colors duration-200 truncate text-left`}
                >
                  {option}
                </button>
              );
            })}
          </div>
        ) : columns === 1 ? (
          // Single column layout with checkboxes
          <div>
            {options.map((option) => (
              <label
                key={option}
                className="block text-white mb-2 text-left"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #eee",
                  paddingBottom: "8px",
                }}
              >
                <span className="text-sm text-white">{option}</span>
                <input
                  type="checkbox"
                  value={option}
                  checked={selectedOptions.includes(option)}
                  onChange={(e) => onChange(e.target.checked, option)}
                  className="appearance-none w-5 h-5 border border-white rounded checked:bg-white checked:border-transparent focus:outline-none"
                />
              </label>
            ))}
          </div>
        ) : (
          // Multi-column layout with checkboxes (not used with current design)
          <div className={`grid grid-cols-${columns} gap-x-4 gap-y-2`}>
            {options.map((option) => (
              <label
                key={option}
                className="flex items-center justify-between text-black text-left pr-2 mb-2"
                style={{
                  borderBottom: "1px solid #eee",
                  paddingBottom: "4px",
                }}
              >
                <span className="text-sm mr-2 truncate">{option}</span>
                <input
                  type="checkbox"
                  value={option}
                  checked={selectedOptions.includes(option)}
                  onChange={(e) => onChange(e.target.checked, option)}
                  className="appearance-none w-4 h-4 border border-black rounded checked:bg-black checked:border-transparent focus:outline-none flex-shrink-0"
                />
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

// Fixed MobileFilterButton with absolute positioning
function MobileFilterButton({ isOpen, onClick, filterCount }) {
  // Return null if isOpen is true to make the button disappear
  if (isOpen) {
    return null;
  }
  
  // Only render the button when the filter is closed
  return (
    <button
      className="p-1.5 rounded-lg bg-black bg-opacity-80 fixed z-[200] w-9 h-9
      flex items-center justify-center 
      text-white font-bold text-lg shadow-lg relative"
      style={{
        position: 'fixed',
        bottom: '16px',
        left: '16px',
        transform: 'none',
        margin: '0'
      }}
      onClick={onClick}
    >
      <img
        src="/Icons and Logos/settings-sliders.svg"
        alt="Open Filters"
        className="invert w-6 h-6"
      />
      {filterCount > 0 && (
        <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
          {filterCount}
        </span>
      )}
    </button>
  );
}


// Desktop filter panel header
function DesktopFilterPanel({ isOpen, showFilter, togglePanels, filterCount, children }) {
  return (
    <div
      className={`border-b border-black backdrop-blur-lg bg-black bg-opacity-20 w-full transition-transform duration-300 ease-in-out 
      ${showFilter ? "translate-y-0" : "-translate-y-16 md:-translate-y-20"} 
      ${isOpen ? "h-screen" : ""}
      z-[100] px-0 md:px-4`}
    >
      <div 
        className="flex w-full content-center place-items-center justify-center cursor-pointer py-2"
        onClick={togglePanels}
      >
        <div className="flex w-full filter-toggle content-center text-2xl items-center ml-6 md:ml-14 relative text-white">
          <img
            src="/Icons and Logos/settings-sliders.svg"
            alt="Settings Icon"
            className="filter-icon content-center mr-5 h-5 w-5 invert"
          />
          Filters
          {filterCount > 0 && (
            <span className="ml-2 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
              {filterCount}
            </span>
          )}
        </div>
      </div>
      {children}
    </div>
  );
}
// Updated MobileFilterPanel with consistent blur effect
function MobileFilterPanel({ isOpen, children }) {
  return (
    <div
      className={`fixed transform ${isOpen ? "translate-x-0" : "-translate-x-full"}
      bottom-0 left-0 transition-transform duration-300 ease-in-out z-[90]`}
      style={{
        width: "85%",
        height: "90vh",
        top: "20vh",
        overflow: "hidden" // Hide overflow on the outer container
      }}
    >
      {/* Inner scrollable container with blur effect applied to the container itself */}
      <div 
        className="h-full shadow-sm overflow-y-auto"
        style={{
          overflowY: isOpen ? "auto" : "hidden",
          overflowX: "hidden",
          height: "100%"
        }}
      >
        {/* Content wrapper with padding */}
        <div className="p-4">
          {isOpen && children}
        </div>
      </div>
    </div>
  );
}

// Apply Filters Button
function ApplyFiltersButton({ onClick, clearFilters, isDesktop }) {
  return (
    <div className={`${isDesktop ? "col-span-full" : ""} flex justify-center gap-4 mt-6 mb-10`}>
      <button
        onClick={onClick}
        className="bg-black bg-opacity-60 text-white py-2.5 px-6 md:px-8 rounded-md hover:bg-opacity-80 transition-all flex-1 max-w-xs font-medium shadow-md"
      >
        Apply Filters
      </button>
      <button
        onClick={clearFilters}
        className="bg-gray-100 bg-opacity-60 text-black py-2.5 px-6 md:px-8 rounded-md hover:bg-opacity-80 transition-all flex-1 max-w-xs font-medium shadow-sm"
      >
        Clear All
      </button>
    </div>
  );
}

// Main Filter Component
function Filter({ filterOptions, onFilterChange }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilter, setShowFilter] = useState(true);
  const lastScrollY = useRef(0);
  const [isOpen, setIsOpen] = useState(false);
  const [width] = useWindowSize();
  const isDesktop = width >= 720;
  
  // Store temporary filter state
  const [tempSelectedFilters, setTempSelectedFilters] = useState({
    priceRanges: [],
    brands: [],
    gender: [],
    origin: [],
    movement: [],
    priceRange: { min: 0, max: 1000000 }
  });
  
  // Store actual applied filters
  const [selectedFilters, setSelectedFilters] = useState({
    priceRanges: [],
    brands: [],
    gender: [],
    origin: [],
    movement: [],
    priceRange: { min: 0, max: 1000000 }
  });
  
  // Count total active filters
  const activeFilterCount = useMemo(() => {
    // Count filters from array-based filters
    const arrayFiltersCount = Object.entries(selectedFilters)
      .filter(([key]) => key !== 'priceRange')
      .reduce((count, [_, value]) => count + (Array.isArray(value) ? value.length : 0), 0);
    
    // Add 1 if price range is active
    const isPriceRangeActive = 
      selectedFilters.priceRange.min > 0 || 
      selectedFilters.priceRange.max < 1000000;
    
    return arrayFiltersCount + (isPriceRangeActive ? 1 : 0);
  }, [selectedFilters]);

  // Always use 3 columns for brands as requested
  const calculateBrandColumns = () => {
    return 3; // Fixed at 3 columns as requested
  };

  // Sync filters from URL on initial load
  const isInitialRender = useRef(true);

  // Updated useEffect for background scroll locking
useEffect(() => {
  if (isOpen) {
    // Store the current scroll position
    const scrollY = window.scrollY;
    
    // Add styles to prevent body scrolling while keeping position
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = '100%';
    document.body.style.overflowY = 'scroll'; // Keep scrollbar to prevent layout shift
  } else {
    // Re-enable scrolling when filter is closed
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = '';
    document.body.style.overflowY = '';
    
    // Restore scroll position
    if (scrollY) {
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }
  
  return () => {
    // Cleanup function to ensure body scroll is reset
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = '';
    document.body.style.overflowY = '';
  };
}, [isOpen]);
  
  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);
    const newFilters = {
      priceRanges: params.priceRanges ? params.priceRanges.split(",") : [],
      brands: params.brands ? params.brands.split(",") : [],
      gender: params.gender ? params.gender.split(",") : [],
      origin: params.origin ? params.origin.split(",") : [],
      movement: params.movement ? params.movement.split(",") : [],
      priceRange: { 
        min: params.minPrice ? parseInt(params.minPrice, 10) : 0, 
        max: params.maxPrice ? parseInt(params.maxPrice, 10) : 1000000 
      }
    };

    setSelectedFilters(newFilters);
    setTempSelectedFilters(newFilters);
    
    // Notify parent on filter changes (but not on first render)
    if (!isInitialRender.current && onFilterChange) {
      onFilterChange(newFilters);
    }
    isInitialRender.current = false;
  }, [searchParams, onFilterChange]);

  const handlePriceRangeChange = useCallback((min, max) => {
    setTempSelectedFilters(prev => {
      // Only update if the values are different to avoid unnecessary re-renders
      if (prev.priceRange.min === min && prev.priceRange.max === max) {
        return prev;
      }
      return {
        ...prev,
        priceRange: { min, max }
      };
    });
  }, []);

  // Handle filter changes (updates temporary state)
  const handleFilterChange = useCallback((filterType) => (isChecked, value) => {
    setTempSelectedFilters(prev => ({
      ...prev,
      [filterType]: isChecked
        ? [...prev[filterType], value]
        : prev[filterType].filter((item) => item !== value),
    }));
  }, []);

  // Handle filter submission
  const handleSubmitFilters = useCallback(() => {
    // Apply filters
    setSelectedFilters(tempSelectedFilters);
    
    // Update URL
    const newSearchParams = new URLSearchParams();
    
    // Add regular filters
    Object.entries(tempSelectedFilters).forEach(([key, value]) => {
      if (key === 'priceRange') {
        // Handle price range separately
        if (value.min > 0) {
          newSearchParams.set('minPrice', value.min.toString());
        }
        if (value.max < 1000000) {
          newSearchParams.set('maxPrice', value.max.toString());
        }
      } else if (Array.isArray(value) && value.length > 0) {
        // Handle array-based filters
        const cleanedValues = value.map((v) => v.replace(/,/g, ""));
        newSearchParams.set(key, cleanedValues.join(","));
      }
    });
    
    setSearchParams(newSearchParams, { replace: true });
    
    // Notify parent of filter changes
    if (onFilterChange) {
      onFilterChange(tempSelectedFilters);
    }
    
    // Close filter panel
    setIsOpen(false);
  }, [tempSelectedFilters, setSearchParams, onFilterChange]);

  // Handle clearing all filters
  const handleClearFilters = useCallback(() => {
    const emptyFilters = {
      priceRanges: [],
      brands: [],
      gender: [],
      origin: [],
      movement: [],
      priceRange: { min: 0, max: 1000000 }
    };
    
    // Reset both selected and temporary filters
    setSelectedFilters(emptyFilters);
    setTempSelectedFilters(emptyFilters);
    
    // Clear URL parameters
    setSearchParams({}, { replace: true });
    
    // Notify parent of filter changes
    if (onFilterChange) {
      onFilterChange(emptyFilters);
    }
    
    // Close filter panel
    setIsOpen(false);
  }, [setSearchParams, onFilterChange]);

  // Toggle panels
  const togglePanels = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  // Control filter visibility on scroll
  useEffect(() => {
    if (!isDesktop) return;

    const controlFilterVisibility = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY.current && currentScrollY > 100) {
        setShowFilter(false);
      } else if (currentScrollY < lastScrollY.current) {
        setShowFilter(true);
      }
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", controlFilterVisibility);
    return () => window.removeEventListener("scroll", controlFilterVisibility);
  }, [isDesktop]);

  // Handle body scroll when filter is open
  useEffect(() => {
    if (isOpen && isDesktop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen, isDesktop]);

  // Mobile filter content
  const MobileFilterContent = React.memo(function MobileFilterContent() {
    return (
      <>
        <div className="px-4 mb-6">
          <div className="w-full mb-3 font-bold text-left text-white text-lg">
            Price Range
          </div>
          <SimplePriceSlider
            minPrice={0}
            maxPrice={1000000}
            initialMin={tempSelectedFilters.priceRange.min}
            initialMax={tempSelectedFilters.priceRange.max}
            onChange={handlePriceRangeChange}
          />
        </div>
        
        <FilterPanel
          title="Gender"
          options={filterOptions.gender || []}
          selectedOptions={tempSelectedFilters.gender}
          onChange={handleFilterChange("gender")}
          isOpen={true}
          className="px-4"
        />
        
        <FilterPanel
          title="Movement"
          options={filterOptions.movement || []}
          selectedOptions={tempSelectedFilters.movement}
          onChange={handleFilterChange("movement")}
          isOpen={true}
          className="px-4"
        />
        
        <FilterPanel
          title="Origin"
          options={filterOptions.origin || []}
          selectedOptions={tempSelectedFilters.origin}
          onChange={handleFilterChange("origin")}
          isOpen={true}
          className="px-4"
        />
        
        <FilterPanel
          title="Brands"
          options={filterOptions.brands || []}
          selectedOptions={tempSelectedFilters.brands}
          onChange={handleFilterChange("brands")}
          isOpen={true}
          columns={2}
          className="px-4"
          useButtons={true}
        />
        
        <div className="px-4">
          <ApplyFiltersButton 
            onClick={handleSubmitFilters} 
            clearFilters={handleClearFilters}
            isDesktop={false}
          />
        </div>
        
        <div className="h-16"></div>
      </>
    );
  });


  // Desktop filter content with custom layout
  const DesktopFilterContent = React.memo(function DesktopFilterContent() {
    return (
      <div className="grid grid-cols-12 gap-6">
        {/* Price Range - takes up 4 columns */}
        <div className="col-span-4">
          <div className="mb-6">
            <div className="w-full mb-3 font-bold text-left text-white text-lg">
              Price Range
            </div>
            <SimplePriceSlider
              minPrice={0}
              maxPrice={1000000}
              initialMin={tempSelectedFilters.priceRange.min}
              initialMax={tempSelectedFilters.priceRange.max}
              onChange={handlePriceRangeChange}
            />
          </div>
          
          {/* Gender directly below Price Range */}
          <FilterPanel
            title="Gender"
            options={filterOptions.gender || []}
            selectedOptions={tempSelectedFilters.gender}
            onChange={handleFilterChange("gender")}
            isOpen={true}
          />
        </div>
        
        {/* Movement and Origin stacked in middle section - takes up 2 columns */}
        <div className="col-span-2">
          <FilterPanel
            title="Movement"
            options={filterOptions.movement || []}
            selectedOptions={tempSelectedFilters.movement}
            onChange={handleFilterChange("movement")}
            isOpen={true}
          />
          
          <FilterPanel
            title="Origin"
            options={filterOptions.origin || []}
            selectedOptions={tempSelectedFilters.origin}
            onChange={handleFilterChange("origin")}
            isOpen={true}
          />
        </div>
        
        {/* Brands with 3 columns takes the remaining space - 6 columns */}
        <div className="col-span-6">
          <div className="mb-6">
            <div className="w-full mb-3 font-bold text-left text-white text-lg">
              Brands
            </div>
            <div className="grid grid-cols-3 gap-3">
              {(filterOptions.brands || []).map(brand => {
                const isSelected = tempSelectedFilters.brands.includes(brand);
                return (
                  <button
                    key={brand}
                    onClick={() => handleFilterChange("brands")(isSelected ? false : true, brand)}
                    className={`px-3 py-3 text-sm rounded-md border border-gray-300 hover:border-white
                      ${isSelected ? 'bg-white text-black font-semibold' : 'bg-black bg-opacity-50 text-white'}
                      w-full text-center transition-colors duration-200`}
                  >
                    {brand}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        
        {/* Buttons span full width */}
        <div className="col-span-12">
          <ApplyFiltersButton 
            onClick={handleSubmitFilters} 
            clearFilters={handleClearFilters}
            isDesktop={true}
          />
        </div>
      </div>
    );
  });
  
  // Memoize the FilterContent components
  const memoizedMobileContent = React.useMemo(() => <MobileFilterContent />, [
    filterOptions, 
    tempSelectedFilters,
    handleFilterChange,
    handlePriceRangeChange,
    handleSubmitFilters,
    handleClearFilters
  ]);

  const memoizedDesktopContent = React.useMemo(() => <DesktopFilterContent />, [
    filterOptions, 
    tempSelectedFilters,
    handleFilterChange,
    handlePriceRangeChange,
    handleSubmitFilters,
    handleClearFilters
  ]);

  return (
    <>
      {/* Background overlay when filter is open */}
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-sm z-[80]" 
          onClick={() => setIsOpen(false)}
        />
      )}
      
      <div className="fixed w-full mt-0 md:top-18 z-[100]">
        {isDesktop ? (
          <DesktopFilterPanel 
          isOpen={isOpen} 
          showFilter={showFilter}
          togglePanels={togglePanels}
          filterCount={activeFilterCount}
        >
          {isOpen && (
            <div
              className="w-5/6 max-w-5xl mx-auto fixed overflow-y-auto p-6 
                bg-white bg-opacity-40 backdrop-blur-xl shadow-xl rounded-3xl scrollbar-hide"
              style={{
                maxHeight: "75vh",
                bottom: "0px",
                maxWidth: "calc(100% - 2rem)",
                left: "50%",
                transform: "translateX(-50%)",
                top: showFilter ? "40px" : "0px",
                transition: "top 0.3s",
                zIndex: 100
              }}
            >
              {memoizedDesktopContent}
            </div>
          )}
        </DesktopFilterPanel>
        ) : (
          <div className="absolute z-[100] w-full flex justify-center">
            <MobileFilterButton 
              isOpen={isOpen} 
              onClick={togglePanels}
              filterCount={activeFilterCount}
            />
            
            <MobileFilterPanel 
              isOpen={isOpen} 
              showFilter={showFilter}
            >
              <div className="w-full h-full bg-black bg-opacity-20 backdrop-blur-lg shadow-xl overflow-hidden p-4">
                {isOpen && memoizedMobileContent}
              </div>
            </MobileFilterPanel>
          </div>
        )}
      </div>
    </>
  );
}

export default Filter;
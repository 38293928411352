import React, { useState, useEffect, useRef, useCallback } from "react";
import { collection, getDocs, query, limit, startAfter, where, orderBy, or, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import ProductCard from "../components/ProductCard";
import Filter from "../components/Filter";
import { useSearchParams, useLocation } from "react-router-dom";
import { storeUtils } from "../utils/sessionStorage";

function StorePage() {
  // Use location to detect if we're coming from browser navigation
  const location = useLocation();
  
  // Get saved state from sessionStorage if available
  const savedState = storeUtils.getStoreState();
  
  // Initialize state from saved state or defaults
  const [products, setProducts] = useState(savedState?.products || []);
  const [displayedProducts, setDisplayedProducts] = useState(savedState?.displayedProducts || []);
  const [loading, setLoading] = useState(!savedState);
  const [initialLoading, setInitialLoading] = useState(!savedState);
  const [hasMore, setHasMore] = useState(savedState?.hasMore !== false);
  const [filterOptions, setFilterOptions] = useState(savedState?.filterOptions || {
    priceRanges: [],
    brands: [],
    gender: [],
    origin: [],
    movement: []
  });
  const [searchParams, setSearchParams] = useSearchParams();

  // For infinite scrolling
  const observer = useRef();
  const loadingRef = useRef(null);
  const PAGE_SIZE = 18;
  const lastProductRef = useRef(null); // We can't save Firestore references in sessionStorage
  
  // Track scroll restoration
  const scrollRestored = useRef(false);
  const imagesLoaded = useRef(0);
  const totalImagesInView = useRef(0);
  
  // Track active filters
  const [activeFilters, setActiveFilters] = useState(savedState?.activeFilters || {
    priceRanges: [],
    brands: [],
    gender: [],
    origin: [],
    movement: [],
    priceRange: { min: 0, max: 1000000 } 
  });
  const [areFiltersApplied, setAreFiltersApplied] = useState(false);

  // Track if this is a restored session
  const isRestoredSession = useRef(savedState && savedState.products.length > 0);

  // Track when images are loaded to restore scroll position after content is ready
  const handleImageLoad = useCallback(() => {
    imagesLoaded.current++;
    
    // If we have at least the first screen of images loaded (6 images), or all images are loaded,
    // and we haven't restored scroll yet, do it now
    if (!scrollRestored.current && 
        ((imagesLoaded.current >= Math.min(6, totalImagesInView.current)) || 
         (imagesLoaded.current >= totalImagesInView.current))) {
      
      scrollRestored.current = true;
      
      // Add a small delay to ensure DOM is updated
      setTimeout(() => {
        if (savedState?.scrollPosition > 0) {
          window.scrollTo({
            top: savedState.scrollPosition,
            behavior: 'auto' // Use 'auto' instead of 'smooth' for immediate jump
          });
          console.log('Restored scroll position to:', savedState.scrollPosition);
        }
      }, 50);
    }
  }, [savedState]);

  // Inject image load tracking into ProductCard
  const ProductCardWithTracking = useCallback(({ product, index }) => {
    return (
      <div onLoad={handleImageLoad}>
        <ProductCard product={product} />
      </div>
    );
  }, [handleImageLoad]);

  // Restore scroll position when returning to the page with a restored session
  useEffect(() => {
    // Only attempt scroll restoration if we have a saved scroll position
    if (isRestoredSession.current && savedState?.scrollPosition > 0) {
      // Count how many images we need to track
      totalImagesInView.current = Math.min(displayedProducts.length, 18); // First page
      
      // Try immediate scroll restoration for browsers that support history-based scroll restoration
      window.scrollTo({
        top: savedState.scrollPosition,
        behavior: 'auto'
      });
      
      // Then try again after a short delay as a fallback
      const timer = setTimeout(() => {
        if (!scrollRestored.current) {
          window.scrollTo({
            top: savedState.scrollPosition,
            behavior: 'auto'
          });
          scrollRestored.current = true;
          console.log('Restored scroll position after delay:', savedState.scrollPosition);
        }
      }, 500);
      
      return () => clearTimeout(timer);
    }
  }, [savedState, displayedProducts.length]);

  // Save state to sessionStorage when leaving the page
  useEffect(() => {
    // Only save if we have products loaded
    if (products.length > 0) {
      const handleBeforeUnload = () => {
        storeUtils.saveStoreState({
          products,
          displayedProducts,
          filterOptions,
          activeFilters,
          hasMore,
        });
      };

      // Save state before navigating away
      window.addEventListener('beforeunload', handleBeforeUnload);
      
      // Save state when component unmounts
      return () => {
        storeUtils.saveStoreState({
          products,
          displayedProducts,
          filterOptions,
          activeFilters,
          hasMore,
        });
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [products, displayedProducts, filterOptions, activeFilters, hasMore]);

  // Handle click events for link clicks to save state before navigation
  useEffect(() => {
    const handleLinkClick = () => {
      if (products.length > 0) {
        storeUtils.saveStoreState({
          products,
          displayedProducts,
          filterOptions,
          activeFilters,
          hasMore,
          scrollPosition: window.scrollY
        });
      }
    };

    // Add listeners to all product links
    const productLinks = document.querySelectorAll('a[href^="/product/"]');
    productLinks.forEach(link => {
      link.addEventListener('click', handleLinkClick);
    });

    return () => {
      productLinks.forEach(link => {
        link.removeEventListener('click', handleLinkClick);
      });
    };
  }, [products, displayedProducts, filterOptions, activeFilters, hasMore]);

  // Fetch products on initial load if needed
  useEffect(() => {
    // If we already have products from sessionStorage, don't fetch again
    if (savedState && products.length > 0) {
      setInitialLoading(false);
      setLoading(false);
      return;
    }
    
    const fetchInitialProducts = async () => {
      setInitialLoading(true);
      try {
        // Initial fetch for filter options only
        await fetchFilterOptions();
        
        // Then fetch initial batch of products
        await fetchNextBatch(true);
      } catch (error) {
        console.error("Error in initial fetch:", error);
      } finally {
        setInitialLoading(false);
      }
    };
    
    fetchInitialProducts();
  }, []);

  // Watch for filter changes and reload products when filters change
  useEffect(() => {
    // Only run this effect if it's not the initial load
    if (!initialLoading) {
      // Reset product state when filters change
      setProducts([]);
      setDisplayedProducts([]);
      lastProductRef.current = null;
      setHasMore(true);
      
      // Clear previous restored state flags
      scrollRestored.current = false;
      imagesLoaded.current = 0;
      isRestoredSession.current = false;
      
      // Reload products with new filters
      fetchNextBatch(true);
    }
  }, [activeFilters]);

  // Extract filter options from products sample
  const extractFilterOptions = async (productsData) => {
    // Extract genders, origins, movements from sample
    const genders = [...new Set(productsData.map(p => p.gender || p.GENDER).filter(Boolean))].sort();
    const origins = [...new Set(productsData.map(p => p.origin || p.ORIGIN).filter(Boolean))].sort();
    
    // Get movement values from specifications
    const movementSet = new Set();
    productsData.forEach(p => {
      if (p.specifications && p.specifications.Movement) {
        movementSet.add(p.specifications.Movement);
      }
    });
    
    setFilterOptions({
      priceRanges: ["0 - 50 000 IQD", "50 000 - 100 000 IQD", "100 000 - 200 000 IQD", "200 000 - 400 000 IQD", "400 000 - 800 000 IQD", "+ 800 000 IQD"],
      brands: filterOptions.brands || [],
      gender: genders,
      origin: origins,
      movement: [...movementSet].sort()
    });
  };

  // Fetch initial filter options from a larger sample
  const fetchFilterOptions = async () => {
    try {
      // Get filter options from metadata document (just ONE read)
      const metadataRef = doc(db, "metadata", "filterOptions");
      const metadataSnapshot = await getDoc(metadataRef);
      
      if (metadataSnapshot.exists()) {
        const metadata = metadataSnapshot.data();
        
        const filterData = {
          priceRanges: metadata.priceRanges || [],
          brands: metadata.brands || [],
          gender: metadata.genders || [],
          origin: metadata.origins || [],
          movement: metadata.movements || []
        };
        
        // Set filter options in state
        setFilterOptions(filterData);
        
        // Cache for future use
        localStorage.setItem('filterOptions', JSON.stringify(filterData));
        localStorage.setItem('filterOptionsTimestamp', Date.now().toString());
        
        console.log("Updated filter options from metadata");
      } else {
        console.log("Metadata document doesn't exist, using fallback method");
        await fetchFilterOptionsFallback();
      }
    } catch (error) {
      console.error("Error fetching filter options:", error);
      // Use fallback method if metadata fetch fails
      await fetchFilterOptionsFallback();
    }
  };
  
  const fetchFilterOptionsFallback = async () => {
    try {
      const productsRef = collection(db, "products");
      const q = query(productsRef, limit(100));
      const smallSampleSnapshot = await getDocs(q);
      
      const sampleProducts = smallSampleSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      // Extract filter options
      const genders = [...new Set(sampleProducts.map(p => p.gender || p.GENDER).filter(Boolean))].sort();
      const origins = [...new Set(sampleProducts.map(p => p.origin || p.ORIGIN).filter(Boolean))].sort();
      
      // Get movement values from specifications
      const movementSet = new Set();
      sampleProducts.forEach(p => {
        if (p.specifications && p.specifications.Movement) {
          movementSet.add(p.specifications.Movement);
        }
      });
      
      // Get all brands (this is still inefficient but it's a fallback)
      const brandQuery = query(productsRef);
      const brandSnapshot = await getDocs(brandQuery);
      
      const allBrands = new Set();
      brandSnapshot.docs.forEach(doc => {
        const data = doc.data();
        const brand = data.brand || data.BRAND;
        if (brand) {
          allBrands.add(brand);
        }
      });
      
      const filterData = {
        priceRanges: ["0 - 50 000 IQD", "50 000 - 100 000 IQD", "100 000 - 200 000 IQD", "200 000 - 400 000 IQD", "400 000 - 800 000 IQD", "+ 800 000 IQD"],
        brands: [...allBrands].sort(),
        gender: genders,
        origin: origins,
        movement: [...movementSet].sort()
      };
      
      setFilterOptions(filterData);
      
      // Cache for future use
      localStorage.setItem('filterOptions', JSON.stringify(filterData));
      localStorage.setItem('filterOptionsTimestamp', Date.now().toString());
    } catch (error) {
      console.error("Error in fallback filter options fetch:", error);
    }
  };

  // Build Firestore query with active filters
  const buildFilteredQuery = (productsRef, startAfterDoc = null) => {
    let constraints = [];
    
    // Only show visible products
    constraints.push(where("visible", "!=", false));
    
    // Add brand filter
    if (activeFilters.brands.length > 0) {
      constraints.push(where("brand", "in", activeFilters.brands));
    }
    
    // Add gender filter
    if (activeFilters.gender.length > 0) {
      constraints.push(where("gender", "in", activeFilters.gender));
    }
    
    // Add origin filter
    if (activeFilters.origin.length > 0) {
      constraints.push(where("origin", "in", activeFilters.origin));
    }
    
    // Build the base query
    let q;
    if (startAfterDoc) {
      q = query(
        productsRef,
        ...constraints,
        startAfter(startAfterDoc),
        limit(PAGE_SIZE)
      );
    } else {
      q = query(
        productsRef,
        ...constraints,
        limit(PAGE_SIZE)
      );
    }
    
    return q;
  };

  // Fetch next batch of products
  const fetchNextBatch = async (isInitialFetch = false) => {
    if (!hasMore && !isInitialFetch) return;
    
    setLoading(true);
    try {
      const productsRef = collection(db, "products");
      
      // Create query with active filters
      const q = buildFilteredQuery(productsRef, isInitialFetch ? null : lastProductRef.current);
      
      const snapshot = await getDocs(q);
      
      if (snapshot.empty) {
        setHasMore(false);
        setLoading(false);
        return;
      }
      
      // Update last product reference for next pagination
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      lastProductRef.current = lastVisible;
      
      const newProducts = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data
        };
      });
      
      // Apply client-side filters for price ranges and movement
      const filteredProducts = newProducts.filter(product => {
        // Skip filtering if no filters are applied
        if (!areFiltersApplied) return true;
        
        // Filter by price
        const price = product.price || product.PRICE || 0;
        let priceMatches = true;

        // Check for min/max price range (from slider)
        if (activeFilters.priceRange) {
          const { min, max } = activeFilters.priceRange;
          const productPrice = typeof price === "number"
            ? price
            : parseInt(price.toString().replace(/[^0-9]/g, ""), 10);
          
          // Only apply filter if min is above 0 or max is below the maximum
          if (min > 0 || max < 1000000) {
            priceMatches = productPrice >= min && productPrice <= max;
          }
        }

        // Check for predefined price ranges from checkboxes (if they exist)
        if (priceMatches && activeFilters.priceRanges && activeFilters.priceRanges.length > 0) {
          priceMatches = activeFilters.priceRanges.some((range) => {
            let minPrice = 0;
            let maxPrice = Infinity;

            if (range.includes("+")) {
              minPrice = parseInt(range.replace(/[^\d]/g, ""), 10);
            } else {
              const rangeParts = range.split(" - ");
              if (rangeParts.length === 2) {
                minPrice = parseInt(rangeParts[0].replace(/[^\d]/g, ""), 10);
                maxPrice = parseInt(rangeParts[1].replace(/[^\d]/g, ""), 10);
              }
            }
            
            const productPrice = typeof price === "number"
              ? price
              : parseInt(price.toString().replace(/[^0-9]/g, ""), 10);
              
            return productPrice >= minPrice && productPrice <= maxPrice;
          });
        }
        
        // Filter by movement
        let movementMatches = true;
        
        if (activeFilters.movement.length > 0) {
          movementMatches = false;
          
          // Try new Firestore structure first
          if (product.specifications && product.specifications.Movement) {
            movementMatches = activeFilters.movement.includes(product.specifications.Movement);
          }
          
          // Fall back to old structure
          if (!movementMatches) {
            for (let i = 1; i <= 40; i++) {
              const infoKey = `Info${i}`;
              const valueKey = `Value${i}`;
              if (product[infoKey] === "Movement" && activeFilters.movement.includes(product[valueKey])) {
                movementMatches = true;
                break;
              }
            }
          }
        }
        
        return priceMatches && movementMatches;
      });
      
      // Add stock status to each product
      const productsWithStockStatus = filteredProducts.map(product => {
        // Check if all stock quantities are 0
        const allStockZero = 
          (product.muthanaQty || 0) === 0 && 
          (product.royalQty || 0) === 0 && 
          (product.whQty || 0) === 0;
          
        return {
          ...product,
          outOfStock: allStockZero
        };
      });
      
      // Validate products to ensure they have required fields
      const validProducts = productsWithStockStatus.filter(product => {
        const hasMainImage = product.imageUrls && 
                             product.imageUrls.main && 
                             product.imageUrls.main.trim() !== '';
        
        const hasBrand = Boolean(product.brand || product.BRAND);
        const hasRef = Boolean(product.reference || product["REF."]);
        const hasPrice = Boolean(product.price || product.PRICE);
        const hasGender = Boolean(product.gender || product.GENDER);
        const hasOrigin = Boolean(product.origin || product.ORIGIN);
        
        return hasMainImage && hasBrand && hasRef && hasPrice && hasGender && hasOrigin;
      });
      
      // Update state based on fetch type
      if (isInitialFetch) {
        setProducts(validProducts);
        setDisplayedProducts(validProducts);
      } else {
        setProducts(prevProducts => [...prevProducts, ...validProducts]);
        setDisplayedProducts(prevProducts => [...prevProducts, ...validProducts]);
      }
      
      // If we got fewer products than the page size, there are no more products
      if (snapshot.docs.length < PAGE_SIZE) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  // Set up intersection observer for infinite scrolling
  useEffect(() => {
    const currentObserverRef = loadingRef.current;
    const currentObserver = observer.current;
    
    if (loading || !hasMore) return;
    
    if (currentObserver) {
      currentObserver.disconnect();
    }
    
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !loading && hasMore) {
        fetchNextBatch();
      }
    }, { 
      threshold: 0.1,
      rootMargin: '200px'
    });
    
    if (currentObserverRef) {
      observer.current.observe(currentObserverRef);
    }
    
    return () => {
      if (currentObserver) {
        currentObserver.disconnect();
      }
    };
  }, [loading, hasMore]);

  // Sync filters from URL parameters
  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);
    const newFilters = {
      priceRanges: params.priceRanges ? params.priceRanges.split(",") : [],
      brands: params.brands ? params.brands.split(",") : [],
      gender: params.gender ? params.gender.split(",") : [],
      origin: params.origin ? params.origin.split(",") : [],
      movement: params.movement ? params.movement.split(",") : [],
      priceRange: { 
        min: params.minPrice ? parseInt(params.minPrice, 10) : 0, 
        max: params.maxPrice ? parseInt(params.maxPrice, 10) : 1000000 
      }
    };

    // Update active filters state
    setActiveFilters(newFilters);
    
    // Check if any filters are active
    const hasActiveFilters = Object.values(newFilters).some(arr => {
      // For arrays (regular filters), check if non-empty
      if (Array.isArray(arr) && arr.length > 0) {
        return true;
      }
      // For price range object, check if min > 0 or max < maxPrice
      if (arr === newFilters.priceRange && (arr.min > 0 || arr.max < 1000000)) {
        return true;
      }
      return false;
    });
    
    setAreFiltersApplied(hasActiveFilters);
  }, [searchParams]); 

  // Handle filter changes
  const handleFilterChange = useCallback((newFilters) => {
    // Update active filters state
    setActiveFilters(newFilters);
    
    // Track if any filters are active
    const hasActiveFilters = Object.values(newFilters).some(arr => {
      // For arrays (regular filters), check if non-empty
      if (Array.isArray(arr) && arr.length > 0) {
        return true;
      }
      // For price range object, check if min > 0 or max < maxPrice
      if (arr === newFilters.priceRange && (arr.min > 0 || arr.max < 1000000)) {
        return true;
      }
      return false;
    });
    
    setAreFiltersApplied(hasActiveFilters);
  }, []);

  if (initialLoading) {
    return <div className="container mx-auto p-16 mt-24 text-center">Loading products...</div>;
  }

  return (
    <div className="min-h-screen">
      <Filter 
        filterOptions={filterOptions} 
        onFilterChange={handleFilterChange}
      />
      
      <div className="p-4 md:p-16 mt-16 md:mt-20" style={{ position: 'relative', zIndex: 1 }}>
        <h1 className="text-2xl hidden font-bold mb-5">Store</h1>
        
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {displayedProducts.map((product, index) => {
            // Create a guaranteed unique key by including array position
            const uniqueKey = `pos-${index}-${product.id || product.reference || product["REF."] || "unknown"}`;
                    
            return (
              <ProductCard key={uniqueKey} product={product} />
            );
          })}
        </div>
        
        {hasMore && (
          <div 
            ref={loadingRef} 
            className="text-center p-4 mt-4"
          >
            {loading ? 'Loading more products...' : 'Scroll for more products'}
          </div>
        )}
        
        {!hasMore && displayedProducts.length > 0 && (
          <div className="text-center p-4 mt-4">
            No more products to load
          </div>
        )}
        
        {displayedProducts.length === 0 && !loading && (
          <div className="text-center p-4 mt-4">
            No products found matching your filters
          </div>
        )}
      </div>
    </div>
  );
}

export default StorePage;
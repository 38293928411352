// src/utils/sessionStorage.js

export const storeUtils = {
    saveStoreState: (data) => {
      try {
        // Save the current scroll position when the state is saved
        const currentState = {
          ...data,
          scrollPosition: data.scrollPosition || window.scrollY,
          timestamp: Date.now()
        };
        
        // Log the saving process to debug
        console.log('Saving store state with scroll position:', currentState.scrollPosition);
        
        sessionStorage.setItem('storeState', JSON.stringify(currentState));
      } catch (error) {
        console.error('Error saving store state to sessionStorage:', error);
      }
    },
    
    getStoreState: () => {
      try {
        const storeData = sessionStorage.getItem('storeState');
        if (!storeData) return null;
        
        const parsedData = JSON.parse(storeData);
        
        // Log the retrieval process
        console.log('Retrieved store state with scroll position:', parsedData.scrollPosition);
        
        return parsedData;
      } catch (error) {
        console.error('Error reading store state from sessionStorage:', error);
        return null;
      }
    },
    
    clearStoreState: () => {
      sessionStorage.removeItem('storeState');
    },
    
    // Add a utility specifically for saving just the scroll position
    saveScrollPosition: () => {
      try {
        const storeData = sessionStorage.getItem('storeState');
        if (!storeData) return;
        
        const parsedData = JSON.parse(storeData);
        
        // Update only the scroll position
        parsedData.scrollPosition = window.scrollY;
        
        console.log('Updating scroll position to:', parsedData.scrollPosition);
        
        sessionStorage.setItem('storeState', JSON.stringify(parsedData));
      } catch (error) {
        console.error('Error saving scroll position to sessionStorage:', error);
      }
    }
  };
// src/characterMapping.js - Updated

export const charMap = {
  " ": "_space_",
  "/": "_slash_",
  "\\": "_backslash_",
  ":": "_colon_",
  "*": "_asterisk_",
  "?": "_question_",
  "\"": "_quote_",
  "<": "_lt_",
  ">": "_gt_",
  "|": "_pipe_",
  "{": "_curly_open_",
  "}": "_curly_close_",
  "[": "_square_open_",
  "]": "_square_close_",
  "`": "_backtick_",
  "^": "_caret_",
  "~": "_tilde_"
};

export const reverseCharMap = Object.entries(charMap).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {});

export const mapCharacters = (reference) => {
  // Check if reference is valid and is a string
  if (!reference || typeof reference !== 'string') {
    return reference || ''; // Return empty string if null/undefined
  }
  
  let mappedRef = reference;
  for (const [char, replacement] of Object.entries(charMap)) {
    const regex = new RegExp(char.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');
    mappedRef = mappedRef.replace(regex, replacement);
  }
  return mappedRef;
};

export const unmapCharacters = (mappedReference) => {
  // Check if mappedReference is valid and is a string
  if (!mappedReference || typeof mappedReference !== 'string') {
    return mappedReference || ''; // Return empty string if null/undefined
  }
  
  let originalRef = mappedReference;
  for (const [replacement, char] of Object.entries(reverseCharMap)) {
    const regex = new RegExp(replacement.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');
    originalRef = originalRef.replace(regex, char);
  }
  return originalRef;
};
// import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
// import { useParams } from "react-router-dom";
// import { doc, getDoc, collection, query, where, getDocs, or } from "firebase/firestore";
// import { db } from "../firebase";
// import { unmapCharacters } from "../characterMapping";

// function ProductPage() {
//   const { productId } = useParams();
//   const [product, setProduct] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [showModal, setShowModal] = useState(false);

//   // Clean the productId to make it usable for Firestore
//   const cleanProductId = unmapCharacters(productId);

//   useEffect(() => {
//     const fetchProduct = async () => {
//       try {
//         // The productId might contain slashes from the URL, which would create an invalid document path
//         // We need to query the collection instead of directly accessing by ID
        
//         const productsCollectionRef = collection(db, "products");
//         const q = query(
//           productsCollectionRef,
//           // Try to match by reference, sku, or id
//           or(
//             where("reference", "==", cleanProductId),
//             where("mappedReference", "==", productId),
//             where("sku", "==", cleanProductId)
//           )
//         );
        
//         const querySnapshot = await getDocs(q);
        
//         if (querySnapshot.empty) {
//           console.log("Product not found in collection");
//           setProduct(null);
//           setLoading(false);
//           return;
//         }
        
//         // Use the first matching product
//         const productDoc = querySnapshot.docs[0];
//         const productData = {
//           id: productDoc.id,
//           ...productDoc.data()
//         };
        
//         setProduct(productData);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching product:", error);
//         setProduct(null);
//         setLoading(false);
//       }
//     };

//     fetchProduct();
//   }, [cleanProductId, productId]);

//   if (loading) {
//     return (
//       <div className="container mx-auto p-4 mt-24">Loading product details...</div>
//     );
//   }

//   if (!product) {
//     return <div className="container mx-auto p-4 mt-24">Product not found.</div>;
//   }

//   const handleImageClick = () => {
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//   };

//   const handleBackdropClick = (e) => {
//     if (e.target.id === "backdrop") {
//       closeModal();
//     }
//   };

//   const formatPrice = (price) => {
//     return price ? `${Number(price).toLocaleString()} IQD` : "No Price";
//   };

//   // Get the brand and reference - handle both data structures
//   const brand = product.brand || product.BRAND || "Unknown Brand";
//   const reference = product.reference || product["REF."] || "No Reference";
//   const description = product.description || product.DESCRIPTION || "";
//   const gender = product.gender || product.GENDER || "";
//   const origin = product.origin || product.ORIGIN || "";
//   const price = product.price || product.PRICE || 0;
//   const discountedPrice = product.discountedPrice || product["DISCOUNTED PRICE"] || 0;

//   // Get image URLs - handle both data structures
//   const mainImageUrl = product.imageUrls?.main || 
//                       `/ProductsData/Images/MAIN/${reference}_mainPic.jpg`;
  
//   const otherImageUrls = product.imageUrls?.others || [];

//   // All images for slider
//   const allImages = [mainImageUrl, ...otherImageUrls];

//   // Slider settings
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//   };

//   // Process specifications - handle both data structures
//   const specifications = [];
  
//   // Check for new Firebase data structure
//   if (product.specifications) {
//     Object.entries(product.specifications).forEach(([key, value]) => {
//       specifications.push({ label: key, value: value });
//     });
//   } else {
//     // Check for old data structure with Info/Value pairs
//     Object.entries(product)
//       .filter(([key, value]) => key.startsWith("Info") && value)
//       .forEach(([key, label]) => {
//         const valueKey = `Value${key.slice(4)}`;
//         specifications.push({
//           label: label,
//           value: product[valueKey] || ""
//         });
//       });
//   }

//   // Check if product is out of stock
//   const isOutOfStock = (() => {
//     // Check for new structure
//     if ('familyQty' in product || 'muthanaQty' in product || 'royalQty' in product || 'whQty' in product) {
//       return (
//         (Number(product.familyQty) || 0) === 0 && 
//         (Number(product.muthanaQty) || 0) === 0 && 
//         (Number(product.royalQty) || 0) === 0 && 
//         (Number(product.whQty) || 0) === 0
//       );
//     }
    
//     // Check for old structure
//     if ('FAMILY QYT.' in product || 'MUTHANA QYT.' in product || 'ROYAL QYT.' in product || 'WH QYT.' in product) {
//       return (
//         (Number(product['FAMILY QYT.']) || 0) === 0 && 
//         (Number(product['MUTHANA QYT.']) || 0) === 0 && 
//         (Number(product['ROYAL QYT.']) || 0) === 0 && 
//         (Number(product['WH QYT.']) || 0) === 0
//       );
//     }
    
//     // If no quantity fields are found, assume out of stock
//     return true;
//   })();

//   return (
//     <div className="container mx-auto px-4 mt-6 md:mt-24 flex flex-col md:flex-row items-start overflow-hidden">
//       <div className="md:w-3/5 w-full p-4 grid grid-cols-1 justify-center items-start">
//         <div className="flex justify-center items-start relative">
//           <img
//             src={mainImageUrl}
//             alt={description || brand}
//             className="h-[calc(80vh-40px)] object-contain md:p-10 cursor-pointer"
//             onClick={handleImageClick}
//           />
          
//           {/* Out of Stock Overlay */}
//           {isOutOfStock && (
//             <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
//               <span className="text-white font-bold text-xl px-6 py-3 bg-red-600 rounded-lg shadow-lg">
//                 OUT OF STOCK
//               </span>
//             </div>
//           )}
//         </div>
        
//         {/* Thumbnail gallery for other images */}
//         {otherImageUrls.length > 0 && (
//           <div className="grid grid-cols-2 gap-4 mt-4">
//             {otherImageUrls.map((img, index) => (
//               <img
//                 key={index}
//                 src={img}
//                 alt={`Additional Image ${index + 1}`}
//                 className="w-full object-cover cursor-pointer"
//                 onClick={handleImageClick}
//               />
//             ))}
//           </div>
//         )}
//       </div>

//       <div className="md:w-2/5 w-full p-4 mt-10 md:mt-24">
//         <h1 className="text-3xl font-bold mb-4">{brand}</h1>
//         <h2 className="text-lg mb-3">{reference}</h2>
//         <p className="text-lg text-start mb-4">
//           {discountedPrice ? (
//             <span>
//               <span className="text-red-500 line-through">
//                 {formatPrice(price)}
//               </span>{" "}
//               <span>{formatPrice(discountedPrice)}</span>
//             </span>
//           ) : (
//             <span>{formatPrice(price)}</span>
//           )}
//         </p>
        
//         {description && (
//           <p className="text-lg mb-4">{description}</p>
//         )}
        
//         <div className="grid grid-cols-2 gap-4 mb-4">
//           <div className="font-bold">
//             <div className="mb-4">Origin</div>
//             <div>{gender ? "Gender" : ""}</div>
//           </div>
//           <div className="text-l">
//             <div className="mb-4">{origin}</div>
//             <div>{gender || ""}</div>
//           </div>
//         </div>
        
//         <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
//           {specifications.map((spec, index) => (
//             <React.Fragment key={index}>
//               <div className="font-bold">{spec.label}</div>
//               <div>{spec.value}</div>
//             </React.Fragment>
//           ))}
//         </div>
//       </div>
      
//       {/* Image modal for fullscreen viewing */}
//       {showModal && (
//         <div
//           className="fixed inset-0 overflow-hidden z-[200]"
//           id="backdrop"
//           onClick={handleBackdropClick}
//         >
//           <div className="flex justify-center items-center h-screen w-screen">
//             <div className="relative p-2 rounded bg-white w-full max-w-4xl mx-auto">
//               <Slider {...settings}>
//                 {allImages.map((img, index) => (
//                   <div key={index} className="flex justify-center items-center">
//                     <img
//                       src={img}
//                       alt={`Product Image ${index + 1}`}
//                       className="object-contain h-screen"
//                     />
//                   </div>
//                 ))}
//               </Slider>
//               <button
//                 className="absolute top-6 right-6 text-2xl p-2 text-black"
//                 onClick={closeModal}
//               >
//                 &times;
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default ProductPage;

import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, collection, query, where, getDocs, or } from "firebase/firestore";
import { db } from "../firebase";
import { unmapCharacters } from "../characterMapping";
import ProductCard from "../components/ProductCard";
import ScrollManager from "../utils/ScrollManager";

// Store page key for scroll management
const STORE_PAGE_KEY = "store-page";

function ProductPage() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [similarProducts, setSimilarProducts] = useState([]);

  // Clean the productId to make it usable for Firestore
  const cleanProductId = unmapCharacters(productId);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        // The productId might contain slashes from the URL, which would create an invalid document path
        // We need to query the collection instead of directly accessing by ID
        
        const productsCollectionRef = collection(db, "products");
        const q = query(
          productsCollectionRef,
          // Try to match by reference, sku, or id
          or(
            where("reference", "==", cleanProductId),
            where("mappedReference", "==", productId),
            where("sku", "==", cleanProductId)
          )
        );
        
        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.empty) {
          console.log("Product not found in collection");
          setProduct(null);
          setLoading(false);
          return;
        }
        
        // Use the first matching product
        const productDoc = querySnapshot.docs[0];
        const productData = {
          id: productDoc.id,
          ...productDoc.data()
        };
        
        setProduct(productData);
        
        // After setting the product, fetch similar products if available
        if (productData.similarProducts) {
          fetchSimilarProducts(productData.similarProducts);
        }
        
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product:", error);
        setProduct(null);
        setLoading(false);
      }
    };

    fetchProduct();
  }, [cleanProductId, productId]);


  // Handle back button to preserve scroll position
  const handleBackToStore = (e) => {
    e.preventDefault();
    
    // Since we're using a direct script in index.html for scroll management,
    // we want to use native browser back navigation
    window.history.back();
  };

  // Function to fetch similar products
  const fetchSimilarProducts = async (similarProductsRefs) => {
    try {
      // Parse the similarProducts string into an array
      let similarRefsArray = [];
      
      if (typeof similarProductsRefs === 'string') {
        // Split by commas, newlines, or spaces
        similarRefsArray = similarProductsRefs.split(/[,\n\s]+/).filter(ref => ref.trim() !== '');
      } else if (Array.isArray(similarProductsRefs)) {
        similarRefsArray = similarProductsRefs;
      }
      
      if (similarRefsArray.length === 0) return;
      
      // Limit to max 4 similar products
      similarRefsArray = similarRefsArray.slice(0, 4);
      
      const productsCollectionRef = collection(db, "products");
      const similarProductsData = [];
      
      // For each reference, try to find the corresponding product
      for (const ref of similarRefsArray) {
        const trimmedRef = ref.trim();
        if (!trimmedRef) continue;
        
        const q = query(
          productsCollectionRef,
          where("reference", "==", trimmedRef)
        );
        
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const productDoc = querySnapshot.docs[0];
          similarProductsData.push({
            id: productDoc.id,
            ...productDoc.data()
          });
        }
      }
      
      setSimilarProducts(similarProductsData);
    } catch (error) {
      console.error("Error fetching similar products:", error);
    }
  };

  if (loading) {
    return (
      <div className="container mx-auto p-4 mt-24">Loading product details...</div>
    );
  }

  if (!product) {
    return <div className="container mx-auto p-4 mt-24">Product not found.</div>;
  }

  const handleImageClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleBackdropClick = (e) => {
    if (e.target.id === "backdrop") {
      closeModal();
    }
  };

  const formatPrice = (price) => {
    return price ? `${Number(price).toLocaleString()} IQD` : "No Price";
  };

  // Get the brand and reference - handle both data structures
  const brand = product.brand || product.BRAND || "Unknown Brand";
  const reference = product.reference || product["REF."] || "No Reference";
  const description = product.description || product.DESCRIPTION || "";
  const gender = product.gender || product.GENDER || "";
  const origin = product.origin || product.ORIGIN || "";
  const price = product.price || product.PRICE || 0;
  const discountedPrice = product.discountedPrice || product["DISCOUNTED PRICE"] || 0;

  // Get image URLs - handle both data structures
  const mainImageUrl = product.imageUrls?.main || 
                      `/ProductsData/Images/MAIN/${reference}_mainPic.jpg`;
  
  const otherImageUrls = product.imageUrls?.others || [];

  // All images for slider
  const allImages = [mainImageUrl, ...otherImageUrls];

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // Process specifications - handle both data structures
  const specifications = [];
  
  // Check for new Firebase data structure
  if (product.specifications) {
    Object.entries(product.specifications).forEach(([key, value]) => {
      specifications.push({ label: key, value: value });
    });
  } else {
    // Check for old data structure with Info/Value pairs
    Object.entries(product)
      .filter(([key, value]) => key.startsWith("Info") && value)
      .forEach(([key, label]) => {
        const valueKey = `Value${key.slice(4)}`;
        specifications.push({
          label: label,
          value: product[valueKey] || ""
        });
      });
  }

  // Check if product is out of stock
  const isOutOfStock = (() => {
    // Check for new structure
    if ('familyQty' in product || 'muthanaQty' in product || 'royalQty' in product || 'whQty' in product) {
      return (
        (Number(product.familyQty) || 0) === 0 && 
        (Number(product.muthanaQty) || 0) === 0 && 
        (Number(product.royalQty) || 0) === 0 && 
        (Number(product.whQty) || 0) === 0
      );
    }
    
    // Check for old structure
    if ('FAMILY QYT.' in product || 'MUTHANA QYT.' in product || 'ROYAL QYT.' in product || 'WH QYT.' in product) {
      return (
        (Number(product['FAMILY QYT.']) || 0) === 0 && 
        (Number(product['MUTHANA QYT.']) || 0) === 0 && 
        (Number(product['ROYAL QYT.']) || 0) === 0 && 
        (Number(product['WH QYT.']) || 0) === 0
      );
    }
    
    // If no quantity fields are found, assume out of stock
    return true;
  })();

  return (
    <div className="container mx-auto px-4 mt-6 md:mt-24">  
      <div className="flex flex-col md:flex-row items-start overflow-hidden">
        <div className="md:w-3/5 w-full p-4 grid grid-cols-1 justify-center items-start">
          <div className="flex justify-center items-start relative">
            <img
              src={mainImageUrl}
              alt={description || brand}
              className="h-[calc(80vh-40px)] object-contain md:p-10 cursor-pointer"
              onClick={handleImageClick}
            />
            
            {/* Out of Stock Overlay */}
            {isOutOfStock && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <span className="text-white font-bold text-xl px-6 py-3 bg-red-600 rounded-lg shadow-lg">
                  OUT OF STOCK
                </span>
              </div>
            )}
          </div>
          
          {/* Thumbnail gallery for other images */}
          {otherImageUrls.length > 0 && (
            <div className="grid grid-cols-2 gap-4 mt-4">
              {otherImageUrls.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`Additional Image ${index + 1}`}
                  className="w-full object-cover cursor-pointer"
                  onClick={handleImageClick}
                />
              ))}
            </div>
          )}
        </div>

        <div className="md:w-2/5 w-full p-4 mt-10 md:mt-24">
          <h1 className="text-3xl font-bold mb-4">{brand}</h1>
          <h2 className="text-lg mb-3">{reference}</h2>
          <p className="text-lg text-start mb-4">
            {discountedPrice ? (
              <span>
                <span className="text-red-500 line-through">
                  {formatPrice(price)}
                </span>{" "}
                <span>{formatPrice(discountedPrice)}</span>
              </span>
            ) : (
              <span>{formatPrice(price)}</span>
            )}
          </p>
          
          {description && (
            <p className="text-lg mb-4">{description}</p>
          )}
          
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="font-bold">
              <div className="mb-4">Origin</div>
              <div>{gender ? "Gender" : ""}</div>
            </div>
            <div className="text-l">
              <div className="mb-4">{origin}</div>
              <div>{gender || ""}</div>
            </div>
          </div>
          
          <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
            {specifications.map((spec, index) => (
              <React.Fragment key={index}>
                <div className="font-bold">{spec.label}</div>
                <div>{spec.value}</div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      
      {/* Similar Products Section */}
      {similarProducts.length > 0 && (
        <div className="w-full mt-12 px-4">
          <h2 className="text-2xl font-bold mb-6">Similar Products</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {similarProducts.map((similarProduct, index) => 
              // Make sure to pass valid product data to ProductCard
              similarProduct && Object.keys(similarProduct).length > 0 ? (
                <ProductCard key={index} product={similarProduct} />
              ) : null
            )}
          </div>
        </div>
      )}
      
      {/* Image modal for fullscreen viewing */}
      {showModal && (
        <div
          className="fixed inset-0 overflow-hidden z-[200]"
          id="backdrop"
          onClick={handleBackdropClick}
        >
          <div className="flex justify-center items-center h-screen w-screen">
            <div className="relative p-2 rounded bg-white w-full max-w-4xl mx-auto">
              <Slider {...settings}>
                {allImages.map((img, index) => (
                  <div key={index} className="flex justify-center items-center">
                    <img
                      src={img}
                      alt={`Product Image ${index + 1}`}
                      className="object-contain h-screen"
                    />
                  </div>
                ))}
              </Slider>
              <button
                className="absolute top-6 right-6 text-2xl p-2 text-black"
                onClick={closeModal}
              >
                &times;
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductPage;
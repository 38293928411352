import React from 'react';

function ContactPage() {
  return (
    <div className="contact  mt-20 p-4 md:p-16">
      <style>
        {`
          .map-container {
            position: relative;
          }
          .map-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 60px; /* Adjust the height to cover the message */
            background-color: white;
            z-index: 1;
          }
        `}
      </style>
     
      <div className="my-8">
        <h2 className="text-2xl font-bold my-4">Our Locations</h2>
        <div className="store-locations grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="location-item p-4 border">
            <h3 className="font-bold">Erbil</h3>
            <p>Royal Mall, Ground floor</p>
            <p>Phone: 07511151003</p>
            <img src="path/to/royal-mall.jpg" alt="Royal Mall" className="my-2 w-full"/>
            <a href="https://maps.app.goo.gl/ugYRtyoeFTH9W67y7" className="text-blue-500">Get Directions</a>
          </div>
          <div className="location-item p-4 border">
            <h3 className="font-bold">Mosul</h3>
            <p>Al-Muthanna Street</p>
            <p>Phone: 07704121119</p>
            <img src="path/to/mosul-store.jpg" alt="Mosul Store" className="my-2 w-full"/>
            <a href="https://maps.app.goo.gl/XGvKwPTxAdqTX5YK7" className="text-blue-500">Get Directions</a>
          </div>
        </div>
      </div>
      <div className="my-8">
        <h2 className="text-2xl font-bold my-0">Find Us on the Map</h2>
        <div className="map-container">
          <div className="map-overlay"></div>
          <iframe
            src="https://www.google.com/maps/d/u/0/embed?mid=1mt9bMkpBfI6kq4bg3VBzPGKw8MwFVMA&ehbc=2E312F"            width="100%"
            height="480"
            frameBorder="0"
            style={{ border: "0" }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </div>
      
    </div>
  );
}

export default ContactPage;

import React, { useState, useRef, useEffect } from "react";

function SimplePriceSlider({ minPrice = 0, maxPrice = 1000000, onChange, initialMin, initialMax }) {
  // Use refs to track if values have been initialized
  const initialized = useRef(false);
  const lastMin = useRef(initialMin !== undefined ? initialMin : minPrice);
  const lastMax = useRef(initialMax !== undefined ? initialMax : maxPrice);
  
  // State for the current values
  const [min, setMin] = useState(initialMin !== undefined ? initialMin : minPrice);
  const [max, setMax] = useState(initialMax !== undefined ? initialMax : maxPrice);
  const [minInput, setMinInput] = useState(min.toString());
  const [maxInput, setMaxInput] = useState(max.toString());

  // Only call onChange when values actually change and not on first render
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      return;
    }
    
    // Only call onChange if values have actually changed
    if (min !== lastMin.current || max !== lastMax.current) {
      lastMin.current = min;
      lastMax.current = max;
      if (onChange) {
        onChange(min, max);
      }
    }
  }, [min, max, onChange]);

  // Handle slider input changes
  const handleMinChange = (e) => {
    const newMin = Number(e.target.value);
    setMin(newMin);
    setMinInput(newMin.toString());
    
    // Ensure min doesn't exceed max
    if (newMin > max) {
      setMax(newMin);
      setMaxInput(newMin.toString());
    }
  };

  const handleMaxChange = (e) => {
    const newMax = Number(e.target.value);
    setMax(newMax);
    setMaxInput(newMax.toString());
    
    // Ensure max doesn't go below min
    if (newMax < min) {
      setMin(newMax);
      setMinInput(newMax.toString());
    }
  };

  // Handle text input changes
  const handleMinInputChange = (e) => {
    setMinInput(e.target.value);
  };

  const handleMaxInputChange = (e) => {
    setMaxInput(e.target.value);
  };

  // Apply text input values when blurred
  const handleMinInputBlur = () => {
    let newMin = Number(minInput.replace(/[^0-9]/g, ''));
    if (isNaN(newMin)) newMin = minPrice;
    
    if (newMin < minPrice) newMin = minPrice;
    if (newMin > max) newMin = max;
    
    setMin(newMin);
    setMinInput(newMin.toString());
  };

  const handleMaxInputBlur = () => {
    let newMax = Number(maxInput.replace(/[^0-9]/g, ''));
    if (isNaN(newMax)) newMax = maxPrice;
    
    if (newMax > maxPrice) newMax = maxPrice;
    if (newMax < min) newMax = min;
    
    setMax(newMax);
    setMaxInput(newMax.toString());
  };

  // Handle key press in input fields
  const handleKeyPress = (e, isMin) => {
    if (e.key === 'Enter') {
      if (isMin) {
        handleMinInputBlur();
      } else {
        handleMaxInputBlur();
      }
    }
  };

  // Format number for display
  const formatNumber = (num) => {
    return num.toLocaleString();
  };

  return (
    <div className="w-full my-4">
      <div className="flex justify-between items-center mb-2">
        <div className="min-price flex-1">
          <input
            type="text"
            value={minInput}
            onChange={handleMinInputChange}
            onBlur={handleMinInputBlur}
            onKeyPress={(e) => handleKeyPress(e, true)}
            className="w-full p-1 border border-gray-300 rounded text-center"
            aria-label="Minimum price"
          />
          <div className="text-sm text-gray-600 text-center">Min</div>
        </div>
        <span className="mx-2">-</span>
        <div className="max-price flex-1">
          <input
            type="text"
            value={maxInput}
            onChange={handleMaxInputChange}
            onBlur={handleMaxInputBlur}
            onKeyPress={(e) => handleKeyPress(e, false)}
            className="w-full p-1 border border-gray-300 rounded text-center"
            aria-label="Maximum price"
          />
          <div className="text-sm text-gray-600 text-center">Max</div>
        </div>
      </div>
      
      <div className="flex flex-col">
        <input
          type="range"
          min={minPrice}
          max={maxPrice}
          value={min}
          onChange={handleMinChange}
          className="w-full mb-2"
        />
        
        <input
          type="range"
          min={minPrice}
          max={maxPrice}
          value={max}
          onChange={handleMaxChange}
          className="w-full"
        />
      </div>
      
      <div className="flex justify-between mt-2 text-xs text-gray-600">
        <span>{formatNumber(minPrice)} IQD</span>
        <span>{formatNumber(maxPrice)} IQD</span>
      </div>
    </div>
  );
}

export default SimplePriceSlider;
// src/services/imageService.js

import localforage from 'localforage';

// Configure localforage for image caching
const imageCache = localforage.createInstance({
  name: 'imageCache',
  storeName: 'images',
  description: 'Cache for product images'
});

// Maximum cache size in bytes (50MB)
const MAX_CACHE_SIZE = 50 * 1024 * 1024;
const CACHE_EXPIRY = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

// Initialize cache metrics
let currentCacheSize = 0;
let cacheEntries = [];

/**
 * Initialize the cache service
 */
export const initImageCache = async () => {
  try {
    // Get all cache keys
    const keys = await imageCache.keys();
    
    // Calculate current cache size and build entry list
    for (const key of keys) {
      try {
        const entry = await imageCache.getItem(key);
        if (entry && entry.data && entry.timestamp) {
          const size = entry.data.length;
          currentCacheSize += size;
          cacheEntries.push({
            key,
            size,
            timestamp: entry.timestamp
          });
        }
      } catch (err) {
        console.error(`Error reading cache entry ${key}:`, err);
      }
    }
    
    // Sort entries by timestamp (oldest first)
    cacheEntries.sort((a, b) => a.timestamp - b.timestamp);
    
    // Clean expired entries
    await cleanExpiredEntries();
    
    console.log(`Image cache initialized. Size: ${(currentCacheSize / 1024 / 1024).toFixed(2)}MB, Entries: ${cacheEntries.length}`);
  } catch (err) {
    console.error('Error initializing image cache:', err);
  }
};

/**
 * Clean expired entries from the cache
 */
const cleanExpiredEntries = async () => {
  const now = Date.now();
  const expiredEntries = cacheEntries.filter(entry => now - entry.timestamp > CACHE_EXPIRY);
  
  for (const entry of expiredEntries) {
    try {
      await imageCache.removeItem(entry.key);
      currentCacheSize -= entry.size;
    } catch (err) {
      console.error(`Error removing expired entry ${entry.key}:`, err);
    }
  }
  
  // Update the entries list
  cacheEntries = cacheEntries.filter(entry => now - entry.timestamp <= CACHE_EXPIRY);
};

/**
 * Make room in the cache if needed
 * @param {number} neededSize - Size in bytes needed for new entry
 */
const makeRoomInCache = async (neededSize) => {
  if (currentCacheSize + neededSize <= MAX_CACHE_SIZE) {
    return; // We have enough room
  }
  
  // Sort by oldest first
  cacheEntries.sort((a, b) => a.timestamp - b.timestamp);
  
  // Remove oldest entries until we have enough room
  while (currentCacheSize + neededSize > MAX_CACHE_SIZE && cacheEntries.length > 0) {
    const oldestEntry = cacheEntries.shift();
    try {
      await imageCache.removeItem(oldestEntry.key);
      currentCacheSize -= oldestEntry.size;
    } catch (err) {
      console.error(`Error removing cache entry ${oldestEntry.key}:`, err);
    }
  }
};

/**
 * Get image from cache or fetch from network
 * @param {string} url - Image URL
 * @param {boolean} priority - Whether this is a high priority image
 * @returns {Promise<string>} - Data URL for the image
 */
export const getImage = async (url, priority = false) => {
  const cacheKey = `img_${url}`;
  
  try {
    // Check cache first
    const cachedImage = await imageCache.getItem(cacheKey);
    
    if (cachedImage && cachedImage.data) {
      // Update the timestamp to indicate recent use
      cachedImage.timestamp = Date.now();
      await imageCache.setItem(cacheKey, cachedImage);
      
      // Update our metrics
      const entryIndex = cacheEntries.findIndex(entry => entry.key === cacheKey);
      if (entryIndex !== -1) {
        cacheEntries[entryIndex].timestamp = cachedImage.timestamp;
      }
      
      return cachedImage.data;
    }
    
    // Not in cache, fetch from network
    const response = await fetch(url, {
      priority: priority ? 'high' : 'auto'
    });
    
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.status} ${response.statusText}`);
    }
    
    const blob = await response.blob();
    const reader = new FileReader();
    
    return new Promise((resolve, reject) => {
      reader.onloadend = async () => {
        const dataUrl = reader.result;
        
        // Don't cache if it's too large (>5MB)
        if (dataUrl.length > 5 * 1024 * 1024) {
          return resolve(dataUrl);
        }
        
        try {
          // Make room in cache if needed
          await makeRoomInCache(dataUrl.length);
          
          // Store in cache
          const entry = {
            data: dataUrl,
            timestamp: Date.now(),
            url
          };
          
          await imageCache.setItem(cacheKey, entry);
          
          // Update our metrics
          currentCacheSize += dataUrl.length;
          cacheEntries.push({
            key: cacheKey,
            size: dataUrl.length,
            timestamp: entry.timestamp
          });
          
          resolve(dataUrl);
        } catch (err) {
          console.error('Error caching image:', err);
          resolve(dataUrl); // Still return the image even if caching fails
        }
      };
      
      reader.onerror = () => {
        reject(new Error('Error reading image data'));
      };
      
      reader.readAsDataURL(blob);
    });
  } catch (err) {
    console.error(`Error fetching image ${url}:`, err);
    throw err;
  }
};

/**
 * Preload images into cache
 * @param {string[]} urls - Array of image URLs to preload
 */
export const preloadImages = async (urls) => {
  if (!urls || urls.length === 0) return;
  
  // Create a queue of promises
  const queue = urls.map(url => {
    return async () => {
      try {
        await getImage(url, false);
      } catch (err) {
        console.error(`Error preloading image ${url}:`, err);
      }
    };
  });
  
  // Process up to 4 images at a time
  const concurrentRequests = 4;
  const activePromises = [];
  
  for (let i = 0; i < Math.min(concurrentRequests, queue.length); i++) {
    const promise = queue[i]();
    activePromises.push(promise);
  }
  
  let nextIndex = concurrentRequests;
  
  while (activePromises.length > 0) {
    await Promise.race(activePromises);
    
    // Find and remove completed promises
    for (let i = activePromises.length - 1; i >= 0; i--) {
      if (activePromises[i].status === 'fulfilled') {
        activePromises.splice(i, 1);
      }
    }
    
    // Add new promises if available
    while (activePromises.length < concurrentRequests && nextIndex < queue.length) {
      const promise = queue[nextIndex]();
      activePromises.push(promise);
      nextIndex++;
    }
  }
};

// Export the service
export default {
  initImageCache,
  getImage,
  preloadImages
};
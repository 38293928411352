import React, { useState } from "react";
import { Link } from "react-router-dom";
import { mapCharacters } from "../characterMapping"; // Import the mapping function

function ProductCard({ product }) {
  // State to track image loading
  const [imageLoaded, setImageLoaded] = useState(false);
  
  // Check if product is undefined before proceeding
  if (!product) {
    console.error("ProductCard received undefined product");
    return null;
  }
  
  // Apply character mapping to REF value - safely handle undefined
  const mappedRef = mapCharacters(product["REF."] || product.reference || ""); 

  // Get image URL - handle both new Firebase structure and old structure
  let imageUrl;
  
  // First try the new Firebase structure
  if (product.imageUrls && product.imageUrls.main) {
    imageUrl = product.imageUrls.main;
  } 
  // Fall back to the old structure if needed
  else {
    imageUrl = `/ProductsData/Images/MAIN/${mappedRef}_mainPic.jpg`;
  }

  // Apply character mapping to SKU or REF value for the product link
  const productLink = `/product/${mapCharacters(
    product.SKU || product.sku || product["REF."] || product.reference || ""
  )}`;

  // Helper function to format price with currency
  const formatPrice = (price) => {
    return price ? `${Number(price).toLocaleString()} IQD` : "No Price";
  };

  // Handle image load event
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <Link to={productLink} className="block">
      <div className="rounded-lg overflow-hidden p-3 mx-0 md:mx-4 my-4 bg-white hover:shadow transition-shadow duration-300">
        <div className="relative">
          {/* Loading placeholder - shows until image is loaded */}
          {!imageLoaded && (
            <div className="w-full h-72 md:h-58 flex justify-center items-center bg-gray-100 animate-pulse">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-800"></div>
            </div>
          )}
          
          <img
            src={imageUrl}
            alt={product.DESCRIPTION || product.description || "Product Image"}
            className={`w-full h-72 md:h-58 object-contain transition-all duration-700 ease-in-out 
              ${imageLoaded ? 'opacity-100 blur-none' : 'opacity-0 blur-sm absolute inset-0'}`}
            onLoad={handleImageLoad}
            onError={(e) => {
              // Fallback for image loading errors
              e.target.src = "/Icons and Logos/placeholder.png";
              e.target.onerror = null; // Prevent infinite error loop
              setImageLoaded(true); // Mark as loaded even with the fallback
            }}
          />
          
          {/* Out of Stock Overlay */}
          {product.outOfStock && imageLoaded && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <span className="text-white font-bold text-lg px-4 py-2 bg-red-600 rounded">
                OUT OF STOCK
              </span>
            </div>
          )}
        </div>
        
        <div className={`mt-3 transition-opacity duration-500 ${imageLoaded ? 'opacity-100' : 'opacity-50'}`}>
          <h2 className="text-lg text-center font-semibold">{product.BRAND || product.brand || "Unknown Brand"}</h2>
          <p className="text-sm text-center">{product["REF."] || product.reference || "No Ref"}</p>
          <p className="text-sm text-center">
            {(product["DISCOUNTED PRICE"] || product.discountedPrice) ? (
              <span>
                <span className="text-red-500 text-bold line-through">
                  {formatPrice(product["PRICE"] || product.price)}
                </span>{" "}
                <span>{formatPrice(product["DISCOUNTED PRICE"] || product.discountedPrice)}</span>
              </span>
            ) : (
              <span>{formatPrice(product["PRICE"] || product.price)}</span>
            )}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default ProductCard;
// App.js

import React, { useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import StorePage from './pages/StorePage';
import ProductPage from './pages/ProductPage'; 
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import { initImageCache } from './services/imageService';
import { StoreProvider } from './contexts/StoreContext'; // Import the StoreProvider

function App() {
  useEffect(() => {
    initImageCache();
  }, []);
  
  return (
    <Router>
       {/* Wrap everything in the StoreProvider */}
        <Header />
       
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/store" element={<StorePage />} />
          <Route path="/product/:productId" element={<ProductPage />} /> 
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
        <Footer />
    </Router>
  );
}

export default App;
import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import ProductCard from '../components/ProductCard';
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";

function HomePage() {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  // Featured product references to display
  const featuredRefs = [
    "T137.407.11.041.00",
    "1514083",
    "A 84936 AA04",
    "BJ7007-02L",
    "T150.410.11.091.00",
    "TW2V24400",
    "TW2U04700"
  ];

  // Fetch featured products from Firestore
  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      setLoading(true);
      try {
        const productsRef = collection(db, "products");
        
        // Create array to store all found products
        const products = [];
        
        // Use Promise.all to run all queries in parallel
        const promises = featuredRefs.map(async (reference) => {
          // Create a query to find products with this reference
          const q = query(
            productsRef,
            where("reference", "==", reference)
          );
          
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
            // Add the first matching product
            const productDoc = querySnapshot.docs[0];
            products.push({
              id: productDoc.id,
              ...productDoc.data()
            });
          }
        });
        
        await Promise.all(promises);
        setFeaturedProducts(products);
      } catch (error) {
        console.error("Error fetching featured products:", error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchFeaturedProducts();
  }, []);

  // Slider settings for main carousel
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // Slider settings for products carousel
  const settingsNewProducts = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  // Featured offers for main slider
  const offers = [
    { src: "/Icons and Logos/HomePageMaterials/Landing Images/2019LGC-1600x800-PR.jpg", link: "/store" },
    { src: "/Icons and Logos/HomePageMaterials/Landing Images/casio-mens-outdoor-watch-prt-b50-main.jpg", link: "/store" },
  ];

  // Brand logos
  const brands = [
    { src: "/Icons and Logos/BrandsLogos/balmain.jpg", link: "/store?brands=Balmain", name: "Balmain" },
    { src: "/Icons and Logos/BrandsLogos/calvinklein.jpg", link: "/store?brands=Calvin+Klein", name: "Calvin Klein" },
    { src: "/Icons and Logos/BrandsLogos/Casio_logo.jpg", link: "/store?brands=Casio", name: "Casio" },
    { src: "/Icons and Logos/BrandsLogos/Citizen_logo_black.jpg", link: "/store?brands=Citizen", name: "Citizen" },
    { src: "/Icons and Logos/BrandsLogos/g-shock.svg", link: "/store?brands=Casio+G-Shock", name: "G-Shock" },
    { src: "/Icons and Logos/BrandsLogos/JOVIAL_Official_Logo.jpg", link: "/store?brands=Jovial", name: "Jovial" },
    { src: "/Icons and Logos/BrandsLogos/longines-logo.jpg", link: "/store?brands=Longines", name: "Longines" },
    { src: "/Icons and Logos/BrandsLogos/Seiko.jpg", link: "/store?brands=Seiko", name: "Seiko" },
    { src: "/Icons and Logos/BrandsLogos/Swiss-Military-.jpg", link: "/store?brands=Swiss+Military", name: "Swiss Military" },
    { src: "/Icons and Logos/BrandsLogos/tag-heuer-1.jpg", link: "/store?brands=Tag+Heuer", name: "Tag Heuer" },
    { src: "/Icons and Logos/BrandsLogos/tissot square.jpg", link: "/store?brands=Tissot", name: "Tissot" },
    { src: "/Icons and Logos/BrandsLogos/versus.jpg", link: "/store?brands=Versus+Versace", name: "Versus" },
    { src: "/Icons and Logos/BrandsLogos/victorinox-swiss-army-logo-vector.jpg", link: "/store?brands=Victorinox", name: "Victorinox" },
    { src: "/Icons and Logos/BrandsLogos/Aerowatch logo.svg", link: "/store?brands=Aerowatch", name: "Aerowatch" },
    { src: "/Icons and Logos/BrandsLogos/tommy hilfiger Logo.svg", link: "/store?brands=Tommy+Hilfiger", name: "Tommy Hilfiger" },
    { src: "/Icons and Logos/BrandsLogos/timex logo.png", link: "/store?brands=Timex", name: "Timex" },
    { src: "/Icons and Logos/BrandsLogos/festina logo.svg", link: "/store?brands=Festina", name: "Festina" },
    { src: "/Icons and Logos/BrandsLogos/boss logo.png", link: "/store?brands=Hugo+Boss", name: "Hugo Boss" },
    { src: "/Icons and Logos/BrandsLogos/Mathey Tissot.png", link: "/store?brands=Mathey-Tissot", name: "Mathey Tissot" },
    { src: "/Icons and Logos/BrandsLogos/Certina logo.svg", link: "/store?brands=Certina", name: "Certina" }
  ];

  // Render brand logo
  const renderBrandLogo = (brand, index) => (
    <a href={brand.link} key={index} className="flex justify-center items-center p-4 shadow-lg hover:shadow-xl transition-shadow duration-300">
      <img src={brand.src} alt={`${brand.name} Logo`} className="h-20 w-auto object-contain" />
    </a>
  );

  return (
    <div className="home overflow-hidden">
      {/* Cover Image or Video Carousel */}
      <Slider {...settings}>
        {offers.map((offer, index) => (
          <div key={index} className="relative object-cover h-screen w-screen">
            <img src={offer.src} alt="Special Offer" className="object-cover h-screen md:w-screen" />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white">
              <h1 className="text-5xl font-bold mb-3">Exclusive Offers</h1>
              <p className="text-xl mb-5">Discover our latest collections on sale</p>
              <a href={offer.link} className="bg-white text-black py-2 px-4 rounded-full text-lg">Shop Now</a>
            </div>
          </div>
        ))}
      </Slider>

      {/* Featured Products Slider */}
      <div className="py-8 mx-12 h-screen slider-container">
        <h2 className="text-3xl font-bold text-center my-6 mb-16">Featured Products</h2>
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <p>Loading featured products...</p>
          </div>
        ) : featuredProducts.length > 0 ? (
          <Slider {...settingsNewProducts}>
            {featuredProducts.map((product, index) => (
              <ProductCard key={index} product={product} />
            ))}
          </Slider>
        ) : (
          <div className="flex justify-center items-center h-64">
            <p>No featured products found. Please check back later.</p>
          </div>
        )}
      </div>

      {/* Collections */}
      <div className="flex flex-wrap w-full">
        <div className="w-full md:w-1/2 p-0">
          <a href="/store?gender=Man%2CUnisex" className="block">
            <div className="bg-cover bg-center h-96 rounded-none shadow-lg" style={{ backgroundImage: "url('/Icons and Logos/HomePageMaterials/Collections/Men Images/5db8a2a58a2c946b0dea96fe_continut2.jpg')" }}>
              <div className="flex items-end h-full w-full bg-gradient-to-t from-black to-transparent p-4">
                <h3 className="text-xl text-white">Gents</h3>
              </div>
            </div>
          </a>
        </div>
        <div className="w-full md:w-1/2 p-0">
          <a href="/store?gender=Unisex%2CWoman" className="block">
            <div className="bg-cover bg-center h-96 rounded-none shadow-lg" style={{ backgroundImage: "url('/Icons and Logos/HomePageMaterials/Collections/Women Images/083ace924a5742a6b082095e217061d2.jpg')" }}>
              <div className="flex items-end h-full w-full bg-gradient-to-t from-black to-transparent p-4">
                <h3 className="text-xl text-white">Ladies</h3>
              </div>
            </div>
          </a>
        </div>
      </div>

      {/* Brands Section */}
      <div className="py-8 px-4">
        <h2 className="text-3xl font-bold text-center my-6">Our Brands</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
          {brands.map(renderBrandLogo)}
        </div>
      </div>

      {/* Delivery and Packaging Services */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 py-8 px-4">
        <div className="flex flex-col items-center">
          <h3 className="text-2xl font-bold mb-2">Fast & Reliable Delivery</h3>
          <p>Get your watches delivered fast and with care anywhere in the world.</p>
        </div>
        <div className="flex flex-col items-center">
          <h3 className="text-2xl font-bold mb-2">Premium Packaging</h3>
          <p>Each watch comes with premium packaging, ensuring a high-class unboxing experience.</p>
        </div>
      </div>
    </div>
  );
}

export default HomePage;